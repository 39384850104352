<template>
  <div class="clearBox2">
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="allot-main">
      <div class="allot-main-left main-box-shadow">
        <div class="left-title">车队</div>
        <div class="allot-main-left-title p20px" >
          <el-input
            style="width: 180px" class="mr15px"
            placeholder="请输入车队名称"
            v-model="searchKey"
          >
          </el-input>
          <el-button type="primary" @click="clickFilterText">搜索</el-button>
          <!-- accordion 手凤琴模式 -->
          <el-tree
            :data="motorcade"
            :props="defaultProps"
            accordion
            @node-expand="getUnallotCar"
            style="padding-top: 10px; overflow: auto;">
            <span slot-scope="{ node, data }" class="custom-tree-node">
              <span @click.stop ="handleNodeClick(data)">{{ node.label }}</span>
            </span>
          </el-tree>
        </div>
      </div>
      <!-- 右侧列表 -->
      <div class="allot-main-right">
        <el-scrollbar style="height: 100%;overflow-x:hidden;">
          <ayl-main title="已分配车辆" padding="10px" :isCollapse="false">
            <div slot="main" style="padding: 24px 24px 0px 24px">
              <ayl-table :table = "allotTable" @selection-change="RemoveAllotCar">
                <div slot="ctrl-button" class="ctrl-button-remove">
                  <el-button type="danger" class="allot" @click="removeCar">移出车队</el-button>
                </div>
              </ayl-table>
            </div>
          </ayl-main>

          <ayl-main title="未分配车辆" padding="10px" :isCollapse="false">
            <div slot="main" style="padding: 24px 24px 0px 24px">
              <ayl-table :table = "table" @selection-change="AddAllotCar">
                <div slot="ctrl-button" class="ctrl-button-remove">
                  <el-button type="danger" class="allot" @click="allotCar">移入车队</el-button>
                </div>
              </ayl-table>
            </div>
          </ayl-main>
          <div style="margin: 24px;display: flex;justify-content: center">
            <el-button @click="onClose">返 回</el-button>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data () {
      const vm = this
      return {
        nav: [{name: "分配车队"}],
        list: [],
        options: [],
        value: [],
        AddCar: [],
        removeCarArray: [],
        loading: false,
        searchKey: null,
        allotTable: {
          api: vm.$api_hw.carTeam_listCarCaseAllocationr,
          //query: null,
          query: {
            groupId: '0',
            companyId: '0',
            queryContent: null,
          },
          hidePagination: true,
          tableHeight: '177px',
          searchData: [{
            type: 'input',
            model: 'queryContent',
            placeholder: '车牌号'
          }],
          columns: [
          {
            type: 'selection',
            width: '300px'
          },{
            title: '车牌号',
            key: 'plateNumber',
          },{
            title: '所属公司',
            key: 'companyName',
          }]
        },
        table: {
          api: vm.$api_hw.carTeam_listCarCaseAllocationr,
          //query: null,
          query: {
            groupId: null,
            companyId: '0',
            queryContent: null,
          },
          hidePagination: true,
          tableHeight: '177px',
          searchData: [{
            type: 'input',
            model: 'queryContent',
            placeholder: '车牌号'
          }],
          columns: [
          {
            type: 'selection',
            width: '300px'
          },{
            title: '车牌号',
            key: 'plateNumber',
          },{
            title: '所属公司',
            key: 'companyName',
          }]
        },
        motorcade: [],
        defaultProps: {
          children: 'children',
          label: 'label'
        }
      }
    },
    methods:{
      /** 手动点击搜索 */
      async clickFilterText(){
        // this.$refs.tree.filter(this.filterText);
        this.motorcade = await this.$api.SearchMotorcade({queryContent: this.searchKey})
      },

      /** 查询车队下边的车辆list信息 */
      async handleNodeClick(data) {
        // this.allotTable.query = {
        //   groupId: data.groupId,
        //   companyId: data.companyId,
        //   queryContent: this.searchKey
        // }
        this.allotTable.query.groupId = data.groupId ? data.groupId : '0'
        this.allotTable.query.companyId = this.table.query.companyId = data.companyId ? data.companyId : '0'
        await this.$search(this.allotTable)
        // await this.$search(this.table)
      },
      /** 查询公司未分配车辆list信息 展开公司查看车队的时候显示的数据列表 */
      async getUnallotCar(data){
        // this.allotTable.query = {
        //   groupId: null,
        //   companyId: data.companyId,
        //   queryContent: this.searchKey
        // }
        this.table.query.companyId = data.companyId
        await this.$search(this.table)
      },
      /** 获取移出车队的车牌号码数据 */
      RemoveAllotCar(val){
        this.removeCarArray = val.map(e=>{
          return e.plateNumber
        })
      },
      /** 获取新增的车牌号码数据 */
      AddAllotCar(val){
        this.AddCar =  val.map(e=>{
          return e.plateNumber
        })
      },
      /** 移入车队 */
      async allotCar(){
        if(this.AddCar.length === 0){
          this.$message.warning('请先选中要移入的车辆！')
          return
        }
        if(this.allotTable.query.groupId !== '0'){
          await this.$api_hw.carTeam_allocateCar({plateNumberList: this.AddCar,  groupId: this.allotTable.query.groupId})
          this.refresh()
        }else{
          this.$message.warning('请先选中要移入的车队！')
        }
      },
      /** 移出车队 */
      async removeCar(){
        if(this.removeCarArray.length === 0){
          this.$message.warning('请先选中要移出的车辆！')
          return
        }
        await this.$api_hw.carTeam_allocateCar({plateNumberList: this.removeCarArray, groupId: null})
        this.refresh()
      },
      /** 刷新三个列表的数据 */
      async refresh(){
        this.motorcade = await this.$api_hw.carTeam_listCompanyAndCarTeam({queryContent: null})
        await this.$search(this.allotTable)
        await this.$search(this.table)
      },
      onClose(){
        BUS.$emit(BUSEVENT.CLOSE_NAV, this.$route);
      }
    },
    async mounted () {
      this.motorcade = await this.$api_hw.carTeam_listCompanyAndCarTeam({queryContent: null})
      // await this.$search(this.table)
    }
  }
</script>

<style lang='sass' scoped>
.clearBox2
  overflow-x: visible !important
  .allot-main
    width: 100%
    height: 100%
    &-left
      z-index: 333
      width: 300px
      height: 100%
      display: inline-block
      vertical-align: top
      background: rgba(255,255,255,1)
      overflow: auto
      &-title
        text-align: center
        padding: 20px
        @include clearfix
        .title
          font-size: $fontX
          color: $black
          border-bottom: 1px solid rgba(232,232,232,1)
    &-right
      margin-left: 24px
      display: inline-block
      width: calc(100% - 300px - 24px)
      height: calc(100% - 28px)
      .ctrl-button-remove
        float: right
.sub-nav-left
  background: rgba(255,255,255,1)
  box-shadow: 1px 0 6px 0 rgba(5,117,230,0.1)
  border-radius: 2px
.left-title
  padding: 10px
  text-align: center
  width: 100%
  font-size: $fontX
  color: $black
  border-bottom: 1px solid rgba(232,232,232,1)
/deep/.el-button--success,.el-button--primary
    color: #FFFFFF
    background-color: rgba(26,188,156,1)
    border-color: rgba(26,188,156,1)
</style>
